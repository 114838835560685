<template>
  <item-edit-view
    title="Услуга"
    :subtitle="item ? item.name : null"
    :loading="loading"
  >
    <template v-slot:right>
      <v-btn
        right
        color="success"
        :loading="saving"
        @click.prevent="save"
      >
        <v-icon small>mdi-content-save</v-icon>
        Сохранить
      </v-btn>
    </template>

    <v-card>
      <v-form v-if="!loading && item">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab key="info">Инфо</v-tab>
          <v-tab key="disclaimers">Дисклеймеры</v-tab>
          <v-tab key="services">Связи</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="info">
            <v-card flat>
              <v-card-text>
                <div>
                  <v-text-field
                    v-model="item.name"
                    label="Название"
                    :disabled="true"
                  ></v-text-field>

                  <!--									<v-checkbox-->
                  <!--										v-model="item.highlight_in_appointment"-->
                  <!--										label="Выделить в записи на прием"-->
                  <!--									></v-checkbox>-->

                  <v-checkbox
                    v-model="item.allowedToCommentWhenBooking"
                    label="Показывать поле 'Комментарий' в записи на прием"
                  ></v-checkbox>

                  <v-checkbox
                    v-model="item.showClinics"
                    label="Показывать список клиник"
                  ></v-checkbox>

                  <!--									<v-input-->
                  <!--										v-if="services"-->
                  <!--										:value="item.related_house_call_service"-->
                  <!--										label='Связанная услуга "вызов на дом"'-->
                  <!--										class="_label-vertical"-->
                  <!--									>-->
                  <!--										<multiselect-->
                  <!--											v-model="item.related_house_call_service"-->
                  <!--											:options="services"-->
                  <!--											:allow-empty="true"-->
                  <!--											:show-labels="false"-->
                  <!--											:multiple="false"-->
                  <!--											:taggable="false"-->
                  <!--											placeholder="Выберите услугу"-->
                  <!--											track-by="uuid"-->
                  <!--											label="name"-->
                  <!--										>-->
                  <!--										</multiselect>-->
                  <!--									</v-input>-->

                  <div>
                    <div class="body-1">Комментарий</div>
                    <v-input :value="item.comment">
                      <quill
                        v-model="item.comment"
                        :options="quillToolbar"
                      >
                      </quill>
                    </v-input>
                  </div>
                  <div>
                    <div class="body-1">Описание</div>
                    <v-input :value="item.description">
                      <quill
                        v-model="item.description"
                        :options="quillToolbar"
                      >
                      </quill>
                    </v-input>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="disclaimers">
            <v-card flat>
              <v-card-text>
                <div>
                  <div class="body-1">Общий</div>
                  <v-input :value="item.disclaimer">
                    <quill
                      v-model="item.disclaimer"
                      :options="quillToolbar"
                    >
                    </quill>
                  </v-input>
                </div>
                <v-divider class="my-1"></v-divider>
                <div>
                  <div class="body-1">По клиникам</div>
                  <v-simple-table dense class="clinics-disclaimer">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left" width="20%">Клиники</th>
                        <th class="text-left" width="13%">C даты</th>
                        <th class="text-left">Дисклеймер</th>
                        <th class="text-left" width="30px"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(v, i) in item.disclaimerClinics">
                        <td width="20%">
                          <v-autocomplete
                            v-model="v.clinics"
                            :items="clinics"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            chips
                            small-chips
                            multiple
                            class="mt-3"
                          ></v-autocomplete>
                        </td>
                        <td width="13%">
                          <v-menu
                            :value="getDisclaimerDateMenu(i)"
                            @input="setDisclaimerDateMenu(i, $event)"
                            :close-on-content-click="false"
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :value="date(v.fromDate)"
                                v-on="on"
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="v.fromDate" no-title
                                           @input="setDisclaimerDateMenu(i, false)"></v-date-picker>
                          </v-menu>
                        </td>
                        <td>
                          <quill v-model="v.disclaimer" :options="quillToolbar"/>
                        </td>
                        <td width="30px">
                          <v-icon small @click="removeDisclaimerRow(i)">delete</v-icon>
                        </td>
                      </tr>
                      </tbody>
                      <tfoot class="d-flex justify-center">
                      <tr>
                        <td colspan="3">
                          <v-btn icon color="success" @click="addDisclaimerRow">
                            <v-icon small>mdi-plus</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="services">
            <v-card flat>
              <v-card-text>

                <div>
                  <label for="relatedPosts">Статьи в блоге</label>
                  <multiselect
                    id="relatedPosts"
                    v-model="item.blogPosts"
                    @search-change="(e) => posts.run(e)"
                    :options="posts.data"
                    :loading="posts.pending"
                    :internal-search="false"
                    allow-empty
                    show-labels
                    searchable
                    taggable
                    multiple
                    track-by="uuid"
                    label="title"
                    placeholder="Статьи в блоге"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card>
  </item-edit-view>
</template>

<script>
import servicesService from '../../services/service'
import clinicsService from '../../services/clinics'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import {QUILL_BASIC} from '../../components/elements/quilljs/configs'
import moment from 'moment'
import { createRequest } from '../../utils/requestsFactory'
import postsService from '../../services/posts'

export default {
  mixins: [createOrUpdateViewMixin(servicesService)],
  data() {
    return {
      services: [],
      clinics: [],
      quillToolbar: QUILL_BASIC,
      tab: null,
      disclaimerDateMenu: [],
      posts: createRequest(async (search) => {
        const { items } = await postsService.getAll({ limit: 60, search })
        return items
      }, [])
    }
  },
  beforeRouteEnter(to, from, next) {
    let clinics = clinicsService.getAll({limit: 60})

    Promise.all([clinics]).then(
      (values) => {
        next(vm => {
          // vm.services = values[0].services
          vm.clinics = values[0].items
        })
      },
      () => {
        next(false)
      }
    )
  },
  methods: {
    addDisclaimerRow() {
      this.item.disclaimerClinics.push({clinics: [], disclaimer: null, fromDate: null})
    },
    removeDisclaimerRow(idx) {
      this.item.disclaimerClinics.splice(idx, 1)
      this.disclaimerDateMenu = this.disclaimerDateMenu.filter(v => v !== value)
    },
    getDisclaimerDateMenu(i) {
      return this.disclaimerDateMenu.indexOf(i) >= 0
    },
    setDisclaimerDateMenu(i, value) {
      if (value) {
        this.disclaimerDateMenu.push(i)
        return
      }
      this.disclaimerDateMenu = this.disclaimerDateMenu.filter(v => v !== i)
    },
    date(v) {
      return v ? moment(v).format('DD.MM.YYYY') : null
    },
  }
}
</script>

<style lang="scss" scoped>
.clinics-disclaimer {
  tr:hover {
    background: #fff !important;
  }
}
</style>
